import { Component, OnInit } from '@angular/core';
import { log } from 'console';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from '@siemens/ix-angular';
import { CrmtJobListComponent } from 'src/app/components/crmt-job-list/crmt-job-list.component';

type CancelJobBody = {
  jobId: string,
  comment?: string,
}

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css']
})
export class JobListComponent implements OnInit {


  public jobId: string = ''
  public refreshed : boolean = false;
  public cancelJobId: string[] = [];
  constructor(
    private _apiService: ApiService,
    private readonly toastService: ToastService
    ) { }

  disableStandard = false

  ngOnInit(): void {
  }

  public selectedJob(jobId: string) {

    this.jobId = jobId
  }

  public selectedJobForCancellation(cancelJobId: string[]) {
    this.cancelJobId = cancelJobId
    console.log("selectedJobForCancellation : " + this.cancelJobId)
  }

  public cancelJob() {
    console.debug("Cancel staring for  : " + this.cancelJobId)

    const cancelJobArray: CancelJobBody[] = this.cancelJobId.map((jobId) => ({
      jobId: jobId.toString(),
      comment: "Cancelling Job from CRMT UI",
    }));

    console.debug("Cancel Job Array", cancelJobArray.toString);

    this._apiService.cancelJobService(cancelJobArray).subscribe({
      next: (response) => {
        this.cancelJobId = [];
        this.showToastMessage();
        console.debug('Response:', response);
      },
      error: (error) => {
        this.cancelJobId = [];
        this.errorToast(error.error.message || "");
        console.error('Error occurred:', error);
      }
    });
    
  }

  async showToastMessage() {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: 'Cancellation Successful',
      type: "success"
    });
  }

  async errorToast(message: string) {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: message,
      type: 'error'
    });
  }

  refreshJobList() {
    this.refreshed = true;
    this.jobId = '';
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: 'Refresh Job List button will be available after 30 seconds.',
      type: 'info'
    });
  }
  onRefreshCompleted() {
    setTimeout(() => {
      this.refreshed = false;
    }, 30000);
  }

}
