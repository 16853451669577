import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import { FileListItem } from '../cloud-file-selector/cloud-file-selector.component';
import { RadioButtonCellRendererComponent } from 'src/app/cell-renderers/radio-button/radio-button-cell-renderer/radio-button-cell-renderer.component';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css']
})
export class FileListComponent implements OnInit {

  @Input() fileList: FileListItem[] = []
  @Output() selection = new EventEmitter<string | undefined>();
  @Output() selectedForDeletion = new EventEmitter<string>();
  @Input() rowSelection: "single" | "multiple" = "single";
  @Input() type: string = 'delete';

  public columnDefs: ColDef[] = [
    { headerName: "Filename", field: 'Key', resizable: true, checkboxSelection: true },
    { headerName: "Size", field: 'Size', resizable: true }
  ]
  
  public columnDefsForDownload: ColDef[] = [
    {
      headerName: '',
      field: 'radio',
      cellRenderer: RadioButtonCellRendererComponent,
      width: 50,
      suppressMenu: true,
    },
    { headerName: "Filename", field: 'Key', resizable: true },
    { headerName: "Size", field: 'Size', resizable: true }
  ];

  constructor() {}

  ngOnInit(): void {
    if(this.type === 'download'){
      this.columnDefs = this.columnDefsForDownload
    }
  }

  onRowSelected(e: RowSelectedEvent): void {
    if(e.node.isSelected()) {
      this.selection.emit(e.data.Key)
      this.selectedForDeletion.emit(e.data.Key)
    }
    else {
      this.selectedForDeletion.emit(e.data.Key)
    }
  }  
}
