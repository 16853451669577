import { Component, Input, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import * as moment from 'moment';
import { DeviceInfoService, StockHistoryItem } from 'src/app/services/device-info.service';

@Component({
  selector: 'app-stock-history',
  templateUrl: './stock-history.component.html',
  styleUrls: ['./stock-history.component.css']
})
export class StockHistoryComponent implements OnInit {

  @Input() device: string | null = null

  public columnDefs: ColDef[] = [
    { headerName: "Vehicle number", field: 'stockNumber', resizable: false },
    { headerName: "Date", field: 'timestamp', resizable: true }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
  };

  // Data that gets displayed in the grid
  public rowData: StockHistoryItem[] = []

  constructor(
    private deviceInfo: DeviceInfoService) { }

    ngOnInit() {
      if (this.device) {
        this.deviceInfo.StockHistory(this.device).subscribe((data: StockHistoryItem[]) => {
          let stockHistoryItem: StockHistoryItem[] = []
          data.forEach((value) => {
            const newStockHistoryItem = { stockNumber: value.stockNumber.trim() || "-", timestamp: moment.unix(value.timestamp as any/1000).local().format('YYYY-MM-DD HH:mm:ss') }
            stockHistoryItem.push(newStockHistoryItem)
          });
          this.rowData = stockHistoryItem
        })
      }
    }
}