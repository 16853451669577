import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { ModalService, ToastService, UploadFileState } from '@siemens/ix-angular';
import { ApiService } from 'src/app/services/api.service';
import { StandardPhonebook } from 'src/app/utils/standard-phonebook';
import { CrmtFileFactory } from 'src/app/utils/crmt-file-parser';
import { CrmtFile, ICrmtFile } from 'src/app/utils/crmt-file';
import { FileListItem } from 'src/app/components/cloud-file-selector/cloud-file-selector.component';
import { FileListComponent } from 'src/app/components/file-list/file-list.component';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { EditStateService } from 'src/app/services/edit-state.service';
import { FileHeaderService } from 'src/app/services/file-header.service';
import { switchMap } from 'rxjs';

type DeleteFileBody = {
  fileName: string,
  comment?: string,
}

@Component({
  selector: 'app-file-edit-page',
  templateUrl: './file-edit-page.component.html',
  styleUrls: ['./file-edit-page.component.css']
})
export class FileEditPageComponent implements OnInit {

  @ViewChild('customToast', { read: TemplateRef })
  customModalRefToast!: TemplateRef<any>;

  @ViewChild('customModal', { read: TemplateRef })
  customModalRef!: TemplateRef<any>;

  @ViewChild('customModalForDeleteWindow', { read: TemplateRef })
  customModalRefForDeleteWindow!: TemplateRef<any>;

  @ViewChild('customModal', { read: FileListComponent })
  customModalRef2!: FileListComponent

  @ViewChild('customModalForUnsavedChanges', { read: TemplateRef })
  customModalForUnsavedChangesRef!: TemplateRef<any>;

  @ViewChild(FileListComponent) fileListComponent?: FileListComponent

  @ViewChildren(FileListComponent) public helloComponents = new QueryList<FileListComponent>();

  public state = UploadFileState.SELECT_FILE;
  public files: File[] = []
  public localOperationsDisabled = true
  public cloudOperationsDisabled = true
  public saveAndUploadDisabled = true
  public newButtonDisable = false
  public loadButtonDisable = false
  public file: CrmtFile = new StandardPhonebook({ name: 'un-named', majorVersion: 0, minorVersion: 0 })
  public fileList: FileListItem[] = []
  public disableStandard = true
  public rowSelection: "single" | "multiple" = "single";

  private fileType: string = 'standard-phonebook'
  private deviceType?: string
  private fileForDownload: string | undefined = undefined
  private filesForDelete: string[] = []
  public isLoading: boolean = false
  public enableEditOption: boolean = false
  public isError: boolean = false
  private originalSelectedValue?: string
  public downloadOkOptionDisabled: boolean = true
  public deleteOptionDisabled: boolean = true
  public fileTypeSelected = false;

  constructor(
    private readonly permissions: PermissionsService,
    private _apiService: ApiService,
    private readonly toastService: ToastService,
    private readonly modalService: ModalService,
    private readonly editStateService: EditStateService,
    private readonly elementRef: ElementRef,
    private fileHeaderService: FileHeaderService
  ) { }

  ngOnInit(): void {

    this.permissions.getUserLevel().subscribe(level => {

      this.disableStandard = level < UserLevel.STANDARD
    })
  }

  validatefileNameVersion(): boolean {
    const fileName = this.fileHeaderService.getFileHeaderValues().name
    const majorVersion = this.fileHeaderService.getFileHeaderValues().majorVersion
    const versionStr = this.fileHeaderService.getFileHeaderValues().versionStr

    if (fileName === '' && (Number.isNaN(majorVersion))) {
      this.errorToast('File name and version can not be empty.')
      return false
    } else if (fileName === '') {
      this.errorToast('File name can not be empty.')
      return false
    } else if (Number.isNaN(majorVersion)) {
      if (versionStr === '') {
        this.errorToast('File version can not be empty.')
      } else {
        this.errorToast('File version is not valid. Enter numbers only')
      }
      return false
    } else {
      return true
    }
  }

  filesChanged(event: any) {
    const files = event.detail
    const filename = files[0].name

    let fileReader: FileReader = new FileReader();

    fileReader.onloadend = (e) => {

      try {
        this.file = CrmtFileFactory.parse(
          this.fileType,
          fileReader.result as ArrayBuffer);
        this.enableEditOption = true
        this.saveAndUploadDisabled = false
        this.showLoadSuccessToastMessage();
      }
      catch (e: any) {

        this.errorToast(`Error reading ${filename}. Make sure you loaded a valid file.`)
      }
    }
    fileReader.readAsArrayBuffer(files[0]);
  }

  private implementsICrmtFile(obj: CrmtFile): obj is ICrmtFile {
    return (<ICrmtFile>obj).toRawData !== undefined;
  }

  uploadClick() {
    const  iCrmtFile: ICrmtFile =  <ICrmtFile>this.file
    const validfileNameVersion = this.validatefileNameVersion()
    if (iCrmtFile && iCrmtFile.entries.length > 0 && validfileNameVersion) {
      if (this.implementsICrmtFile(this.file)) {
        const rawData = this.file.toRawData()
        const filename = `${this.fileType}/${this.deviceType}/${this.file.header.name}`
        this.infoToast('Uploading file...')

        this._apiService.uploadFile(filename, rawData).subscribe(() => {
          this.editStateService.setEditedState(false);
          this.successToast('File uploaded.')
        })
      }
      else {
        this.errorToast('File type not supported.')
      }
    } else {
      if (validfileNameVersion) this.errorToast("Empty file can not be uploaded.")
    }
  }

  downloadClick() {
    this.rowSelection = "single"
    this.downloadOkOptionDisabled = true
    this.openModal()
  }

  deleteClick() {
    this.rowSelection = "multiple"
    this.deleteOptionDisabled = true
    this.openModalForDeleteWindow()
    this.updateFileList()
  }

  public saveClick() {
    const  iCrmtFile: ICrmtFile =  <ICrmtFile>this.file
    const validfileNameVersion = this.validatefileNameVersion()
    if (iCrmtFile && iCrmtFile.entries.length > 0 && validfileNameVersion) {
      if (this.implementsICrmtFile(this.file)) {
        this.editStateService.setEditedState(false);
        this._apiService.save(this.fileType, this.file)
      }
    } else {
      if (validfileNameVersion) this.errorToast("Empty file can not be saved.")
    }
  }

  public newFile() {
    this.enableEditOption = true
    this.saveAndUploadDisabled = false
    this.file = CrmtFileFactory.create(this.fileType)
  }

  async infoToast(message: string) {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: message,
      type: 'info',
      autoClose: true
    });
  }

  async errorToast(message: string) {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: message,
      type: 'error',
      autoClose: true
    });
  }

  async successToast(message: string) {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: message,
      type: 'success',
      autoClose: false
    });
  }

  public fileTypeChanged(event: any): void {
    this.fileTypeSelected = true;
    if(this.editStateService.getEditedState()){
      this.openModalForUnsavedChanges(event)
    }else{
      this.enableEditOption = false
      this.fileType = event
      this.file = CrmtFileFactory.create(this.fileType)

      if(this.fileType == "attached-systems-parameters" || this.fileType == "train-crew-parameters" || this.fileType == "software-package") {
        this.newButtonDisable = true
      } else {
        this.newButtonDisable = false
      }

      if(this.fileType == "software-package") {
        this.loadButtonDisable = true
      } else {
        this.loadButtonDisable = false
      }

      this.localOperationsDisabled = false
      this.saveAndUploadDisabled = true
      this.updateFileList()
    }
  }

  public selectedFileType(event: any){
    this.originalSelectedValue = event
  }


  public deviceTypeChanged(event: any): void {
    this.enableEditOption = false
    this.deviceType = event
    this.cloudOperationsDisabled = false
    this.saveAndUploadDisabled = true
    this.updateFileList()
  }


  private updateFileList() {

    this.fileList = []
    if (this.fileType && this.deviceType) {
      this._apiService.getFileList(
        this.fileType,
        this.deviceType).subscribe((data: FileListItem[]) => {
          this.fileList = data
        })
    }
  }

  async openModal() {
    const instance = await this.modalService.open({
      size: '480',
      content: this.customModalRef,
      data: 'Some data',
    });

    instance.onClose.on((a) => {
      this.enableEditOption = true
      this.isLoading = true
      const filename = `uploads/${this.fileType}/${this.deviceType}/${this.fileForDownload}`

      if (this.fileType == 'software-package') {
        this.showToastMessageForSoftwarePackageDownloading();
        this.enableEditOption = false
        this.isLoading = false
        this._apiService.getSoftwarePackageFileList(filename).pipe(
          switchMap((data: FileListItem[]) => this._apiService.downloadSoftwarePackage(filename, data))
        ).subscribe({
          error: (e) => {
            if (e.status == 0) { // This is the 0 = unknown Error
              this.errorToast("Unable to process your request. Please try again later or contact the administrator.")
            } else {
              this.errorToast(`Error occurred while downloading file. Please contact administrator.`)
            }
          }
        })
      } else {
        this._apiService.download(filename, this.fileType).subscribe({
          next: (data) => {
            this.isLoading = false
            this.isError = false
            this.file = data
            this.saveAndUploadDisabled = false
          },
          error: (e) => {
            if (e.status == 0) {// This is the 0 = unknown Error
              this.errorToast("Unable to process your request. Please try again later or contact the administrator.")
            } else {
              this.errorToast(`Error occurred while downloading file. Please contact administrator.`)
            }
            this.isError = true
            this.isLoading = false
          }
        })
      }
    });

    instance.htmlElement.addEventListener(
      'keydown',
      (keyboardEvent: KeyboardEvent) => {
        console.log(keyboardEvent.key);
      }
    );
  }

  async showToastMessageForSoftwarePackageDownloading() {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: 'File Downloading...',
      type: "info",
      autoCloseDelay: 9000
    });
  }

  public selection(event: string | undefined) {
    if(event != null) {
      this.downloadOkOptionDisabled = false
    }
    this.fileForDownload = event
  }

  public selectedForDeletion(event: string) {
    if (!this.filesForDelete.includes(event)) {
      this.filesForDelete.push(event);
    } else {
      this.filesForDelete = this.filesForDelete.filter(item => item !== event);
    }
    this.deleteOptionMode()
  }

  public deleteOptionMode() {
    if (this.filesForDelete.length >= 1) {
      this.deleteOptionDisabled = false
    } else {
      this.deleteOptionDisabled = true
    }
  }

  async openModalForDeleteWindow() {
    const instance = await this.modalService.open({
      size: '480',
      content: this.customModalRefForDeleteWindow,
      data: 'Some data',
    });

    instance.onClose.on((a) => {
      const deleteFileArray: DeleteFileBody[] = this.filesForDelete.map((fileName) => ({
        fileName: `uploads/${this.fileType}/${this.deviceType}/${fileName}`,
        comment: "Deleting Job from CRMT UI",
      }));
      console.info("Delete Job Array", JSON.stringify(deleteFileArray));

      this._apiService.deleteFileFromAWS(deleteFileArray).subscribe({
        next: (response) => {
          if(response.message.length != 0) {
            this.showToastMessage();
          }
          console.debug('Response:', response);
        },
        error: (error) => {
          this.errorToast(error.error.message || "");
          console.error('Error occurred:', error);
        }
      });
      this.filesForDelete = []
    });

    instance.htmlElement.addEventListener(
      'keydown',
      (keyboardEvent: KeyboardEvent) => {
        console.log(keyboardEvent.key);
      }
    );
  }

  async showToastMessage() {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: 'Files Deletion Successful.',
      type: "success"
    });
  }
  async openModalForUnsavedChanges(event?: any) {
    const instance = await this.modalService.open({
      content: this.customModalForUnsavedChangesRef,
      data: '',
    })

    instance.onClose.on((a) => {//on click of Ok button on popup
      this.editStateService.setEditedState(false)
      this.fileTypeChanged(event)
    });

    instance.onDismiss.on((arg) => { // when cancel is clicked on popup
      const selectedItem = this.elementRef.nativeElement.querySelector('ix-select')
      selectedItem.value = this.originalSelectedValue
    })

    instance.htmlElement.addEventListener(
      'keydown',
      (keyboardEvent: KeyboardEvent) => {
        console.log(keyboardEvent.key);
      }
    );

  }

  ngOnDestroy() {
    this.editStateService.setEditedState(false);
  }


  async showLoadSuccessToastMessage() {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: 'Files Loaded Successfully.',
      type: "success"
    });

  }

}
