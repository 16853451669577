<div class="wrapper">
    <ag-grid-angular
      class="ag-theme-alpine-dark ag-theme-ix"
      style="height: 100%; width: 100%;"
      [columnDefs]="columnDefs"
      [rowData]="rowData$"
      [rowSelection]="'multiple'"
      [animateRows]="true"
      [defaultColDef]="defaultColDef"
      (gridReady)="onGridReady($event)"
      [domLayout]="domLayout"
      [pagination]="pagination"
      [paginationPageSize]="paginationPageSize"
      [rowModelType]="'infinite'"
      [cacheBlockSize]="paginationPageSize"
      (rowSelected)="onRowSelected($event)">
    </ag-grid-angular>
</div>  