import { Component, OnInit } from '@angular/core';
import { ToastService } from '@siemens/ix-angular';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-device-registration-page',
  templateUrl: './device-registration-page.component.html',
  styleUrls: ['./device-registration-page.component.css']
})
export class DeviceRegistrationPageComponent implements OnInit {

  public disableStandard = true
  public invalidData = true
  private deviceType!: string
  private serialNumber!: string

  constructor(
    private readonly permissions: PermissionsService,
    private readonly _apiService: ApiService,
    private readonly toastService: ToastService) {}

  ngOnInit() { 

    this.permissions.getUserLevel().subscribe(level => {

      this.disableStandard = level < UserLevel.STANDARD
    })
  }
  
  public deviceTypeChanged(event: string) {

    this.deviceType = event
    this.validate()
  }

  public serialNumberChanged(event: any) {

    this.serialNumber = event.target.value
    this.validate()
  }

  private validate() {

    this.invalidData = 
      this.deviceType == undefined ||
      this.serialNumber == undefined ||
      this.serialNumber?.length == 0
  }
  public registerDevice() {

    this._apiService.registerDevice(
      this.deviceType,
      this.serialNumber).subscribe({
        next: () => {
          this.showToastMessage()
        },
        error: (e) => {
          this.errorToast(e.message)
        }
      })
  }

  async showToastMessage() {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: `Radio ${this.serialNumber} pending registration.
       Please power on the device within the next 15 minutes to complete registration`,
      type: "success"
    });
  }

  async errorToast(message: string) {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: message,
      type: 'error'
    });
  }

}
