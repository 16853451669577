<div class="wrapper">
    <ix-content-header width="100%"
        hasBackButton="false"
        headerTitle="File Editor"
        headerSubtitle="Create or edit files">
    </ix-content-header>

    <app-file-type-selector 
        (fileType)="fileTypeChanged($event)"
        (originalFileType)="selectedFileType($event)">
    </app-file-type-selector>

    <app-device-type-selector [fileTypeSelected]="fileTypeSelected"
        (deviceType)="deviceTypeChanged($event)">
    </app-device-type-selector>

    <div class="button-panel">
        <ix-button
            class="m-1" 
            outline 
            variant="Primary" 
            [disabled]="cloudOperationsDisabled || newButtonDisable"
            (click)="newFile()">
            New
        </ix-button>        
        <ix-upload
            i18nUploadFile="Load"
            [state]="state"
            [disabled]="cloudOperationsDisabled || loadButtonDisable"
            (filesChanged)="filesChanged($event)">
        </ix-upload>    
        <ix-button 
            class="m-1" 
            outline 
            variant="Primary" 
            [disabled]="localOperationsDisabled || saveAndUploadDisabled"
            (click)="saveClick()">
            Save
        </ix-button>
        <ix-button 
            class="m-1" 
            outline 
            variant="Primary" 
            [disabled]="cloudOperationsDisabled"
            (click)="downloadClick()">
            Download
        </ix-button>
        <ix-button 
            class="m-1" 
            outline 
            variant="Primary" 
            [disabled]="cloudOperationsDisabled || disableStandard || saveAndUploadDisabled"
            (click)="uploadClick()">
            Upload
        </ix-button>
        <ix-button 
            class="m-1" 
            outline 
            variant="Primary" 
            [disabled]="cloudOperationsDisabled || disableStandard"
            (click)="deleteClick()">
            Delete
        </ix-button>
    </div>

    <div *ngIf="enableEditOption;">
        <div class="content" *ngIf="!isLoading; else spin">
            <div *ngIf="!isError;">
                <app-generic-file-editor
                    [file]="file">
                </app-generic-file-editor>
            </div>

        </div>
    </div>

    <ng-template #spin>
        <div class="spinner">
            <p>Loading details ...</p>
        </div>
        <div class="spinner">
            <ix-spinner size="large"></ix-spinner>
        </div>
    </ng-template>

</div>  

<ng-template #customModal let-modal>
    <div>
        <ix-modal-header>Select file to Download</ix-modal-header>
        <ix-modal-content>
            <app-file-list
                [fileList]="fileList"
                [rowSelection]="rowSelection"
                (selection)="selection($event)"
                type="download"
            ></app-file-list>
        </ix-modal-content>
        <ix-modal-footer>
            <ix-button
                outline
                class="dismiss-modal"
                (click)="modal.dismiss('dismiss')">
                Cancel
            </ix-button>
            <ix-button class="close-modal" (click)="modal.close('okay')" [disabled]="downloadOkOptionDisabled">
                OK
            </ix-button>
        </ix-modal-footer>
    </div>
</ng-template>

<ng-template #customModalForDeleteWindow let-modal>
    <div>
        <ix-modal-header>Select file to Delete</ix-modal-header>
        <ix-modal-content>
            <app-file-list
                [fileList]="fileList"
                [rowSelection]="rowSelection"
                (selectedForDeletion)="selectedForDeletion($event)"
            ></app-file-list>
        </ix-modal-content>
        <ix-modal-footer>
            <ix-button
                outline
                class="dismiss-modal"
                (click)="modal.dismiss('dismiss')">
                Cancel
            </ix-button>
            <ix-button class="close-modal" (click)="modal.close('okay')" [disabled]="deleteOptionDisabled">
                Delete
            </ix-button>
        </ix-modal-footer>
    </div>
</ng-template>

<ng-template #customModalForUnsavedChanges let-modal>
    <ix-modal>
      <ix-modal-header> Exit without saving? </ix-modal-header>
      <ix-modal-content>The modified values will be lost. Do you still want to proceed?</ix-modal-content>
      <ix-modal-footer>
        <ix-button outline class="dismiss-modal" (click)="modal.dismiss('dismiss')">
          Cancel
        </ix-button>
        <ix-button class="close-modal" (click)="modal.close('okay')">
          OK
        </ix-button>
      </ix-modal-footer>
    </ix-modal>
  </ng-template>