import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
const { v4: uuidv4 } = require('uuid');

export type NewOrganisationData = {
  newOrganization: string;
  newOrganizationDescription: string;
}

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.css']
})
export class CreateGroupComponent implements OnInit {

  @Output() newOrganizationData = new EventEmitter<NewOrganisationData>()
  @Output() newSubgroup = new EventEmitter<any>()
  @ViewChild('organizationNameInput') organizationNameInput?: ElementRef;
  @ViewChild('organizationDescriptionInput') organizationDescriptionInput?: ElementRef;
  @ViewChild('subgroupNameInput') subgroupNameInput?: ElementRef;

  public selectedTab = 0;
  public groupNames: string[] = []
  private parentGroup: string = ""
  public organizationNameInputValue: string = ""
  public organizationDescriptionInputValue: string = ""
  public subgroupNameInputValue: string = ""
  public disablePrivileged = true
  public disablePrivilegedForSubgroup = true
  public oraganizationNameError: boolean = false;
  public subgroupNameError: boolean = false;
  public disableSubgroup = true

  constructor(
    private readonly permissions: PermissionsService,
    private _apiService: ApiService) {

    _apiService.fleetListGroups().subscribe((data: string[]) => {

      this.groupNames = data
    })
  }

  ngOnInit(): void {
    this.permissions.getUserLevel().subscribe(level => {
      this.disablePrivilegedForSubgroup = level < UserLevel.PRIVILEGED;
    })
  }

  selectionChanged(event: any) {
    this.parentGroup = event.detail[0]

  }

  createOrganization() {
    const newGroup = this.organizationNameInput?.nativeElement.value.toUpperCase().replaceAll(' ', '-')
    const thingGroupDescription = this.organizationDescriptionInput?.nativeElement.value.toUpperCase()
    this._apiService.fleetAddGroup(newGroup, "", thingGroupDescription).subscribe((data: any) => {
      const selectionState: NewOrganisationData = {
        newOrganization: newGroup,
        newOrganizationDescription: thingGroupDescription
      }
      this.newOrganizationData.emit(selectionState)

      this._apiService.fleetListGroups().subscribe((data: string[]) => {
        this.groupNames = data
      })
    })
    this.organizationNameInputValue = ""
    this.organizationDescriptionInputValue = ""
    this.disablePrivileged = true
  }

  createGroup() {
    const groupNameUuid: string = uuidv4();
    const newGroup = this.subgroupNameInput?.nativeElement.value.replaceAll(' ', '-')
    const newGroupName = `${groupNameUuid.toUpperCase()}:${newGroup}`
    this._apiService.fleetAddGroup(newGroupName, this.parentGroup, "").subscribe((data: any) => {
      this.newSubgroup.emit({
        parentGroup: this.parentGroup,
        newGroupName
      })

      this._apiService.fleetListGroups().subscribe((data: string[]) => {

        this.groupNames = data
      })
    })
    this.subgroupNameInputValue = ""
  }

  changeTab(tabIndex: number) {
    this.selectedTab = tabIndex;
    this._apiService.fleetListGroups().subscribe((data: string[]) => {
      this.groupNames = data
    })
  }

  changeInputValue() {
    const pattern = /^[a-zA-Z0-9-_:]*$/;
    this.oraganizationNameError = !pattern.test(this.organizationNameInputValue);

    this.permissions.getUserLevel().subscribe(level => {
      this.disablePrivileged = level < UserLevel.PRIVILEGED
    })
    if (!this.disablePrivileged) {
      if (this.organizationNameInputValue.length == 0) {
        this.disablePrivileged = true
      } else {
        this.disablePrivileged = false
      }
    }
  }

  validateSubgroupName() {
    const pattern = /^[a-zA-Z0-9-_:]*$/;
    this.subgroupNameError = !pattern.test(this.subgroupNameInputValue);
    if(this.parentGroup && this.subgroupNameInputValue.trim().length > 0 && !this.subgroupNameError) {
      this.disableSubgroup = false
    } else {
      this.disableSubgroup = true
    }
  }
}
