import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, DomLayoutType, GridReadyEvent, IGetRowsParams, RowSelectedEvent } from 'ag-grid-community';
import { Observable, of, switchMap } from 'rxjs';
import { DetailsLinkIIComponent } from 'src/app/cell-renderers/details-link/details-link-ii.component';
import * as moment from 'moment';
import { IconCellRendererComponent } from 'src/app/cell-renderers/icon-cell-renderer/icon-cell-renderer.component';

export interface FleetItem {
  name: string;
  position: number;

  // Inventory
  engineNumber: string;
  mtAppver: string;
  mtIMEI: string;
  mtModel: string;
  mtType: string;
  serialNumber: string;
  stockType: string;
  ver: string;

  // Software versions
  currentVersion: string;
  alternateVersion: string;
  currentLanguageVersion: string;

  // Attribute
  connected: string;
  timeConnected: string;
  timeDisconnected: string;

  // Token
  nextToken: string;

  //HMIActivation
  hmiActivated: any;
}

const ELEMENT_DATA: FleetItem[] = []

@Component({
  selector: 'app-fleet-list',
  templateUrl: './fleet-list.component.html',
  styleUrls: ['./fleet-list.component.css']
})
export class FleetListComponent implements OnInit {

  @Output() selectedDevices = new EventEmitter<string[]>();
  @Input() hideOption = false
  
  dataSource = ELEMENT_DATA;

  public columnDefs: ColDef[] = [];

  // Data that gets displayed in the grid
  public rowData$: any[] = [];

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 15;
  public nextToken: string = "";
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  constructor(
    private _apiService: ApiService) { }

  ngOnInit(): void {
    this.columnDefs = [
      { field: 'engineNumber', headerName: 'Stock Number', checkboxSelection: this.hideOption, pinned:'left' },
      { headerName: 'Configuration', cellRenderer: DetailsLinkIIComponent, hide: this.hideOption, sortable: false, filter: false },
      { field: 'connected',cellRenderer:IconCellRendererComponent },
      { field: 'ver',  headerName: 'S/W Version' },
      { field: 'currentVersion',  headerName: 'Current S/W Build' },
      { field: 'alternateVersion',  headerName: 'Available S/W Build' },
      { field: 'serialNumber' },
      { field: 'mtType',  headerName: 'Trx Type' },
      { field: 'mtModel',  headerName: 'Trx Model' },
      { field: 'mtAppver', headerName: 'Trx S/W Version' },
      { field: 'mtIMEI',  headerName: 'Trx IMEI' },
      { field: 'stockType',headerName: 'Vehicle Type' },
      { field: 'currentLanguageVersion' },
      { field: 'timeConnected' },
      { field: 'timeDisconnected' }
    ]
  }

  onGridReady(params: GridReadyEvent) {

    this.agGrid.api = params.api;
    
    const dataSource = {
      getRows: async (rowsParams: IGetRowsParams) => {
        const startRow = rowsParams.startRow;
        const endRow = rowsParams.endRow;
        console.debug("Start row and End row selected for pagination - " + startRow + endRow)
        this.agGrid.api.showLoadingOverlay();

        this.getFleetListDevices(this.nextToken, this.paginationPageSize).subscribe(response => {
          this.rowData$ = response
          const lastRow = response[0].nextToken ? undefined : startRow + response.length;

          this.agGrid.api.hideOverlay();
          rowsParams.successCallback(response, lastRow)
          this.nextToken = response[0].nextToken;
        })
      }
    };
    
    params.api.setDatasource(dataSource);
  }

  private getFleetListDevices(nextToken: string | undefined, paginationPageSize: number): Observable<any[]> {
    return this._apiService.fleetListDevices(nextToken, paginationPageSize).pipe(
      switchMap((fleet: any[]) => {
        const sorted = fleet.sort((a, b) => {
          if (a.thingName < b.thingName) {
            return -1;
          }
          if (a.thingName > b.thingName) {
            return 1;
          }
          return 0;
        });

        const mapped = sorted.map((item, index) => {

          const inventory = item.shadow?.inventory;
          const softwareVersions = item.shadow?.softwareVersions;
          const attributes = item.attributes;
          const nxtToken = item.nextToken;

          let stockType: string;
          switch (inventory?.stockType) {
            case 0: stockType = "Engine"; break;
            case 1: stockType = "Coach"; break;
            default: stockType = "-"; break;
          }

          const newItem: FleetItem = {
            name: item.thingName,
            position: index,

            // Inventory
            engineNumber: inventory?.engineNumber.trim() || "-",
            mtAppver: inventory?.mtAppver || "-",
            mtIMEI: inventory?.mtIMEI || "-",
            mtModel: inventory?.mtModel || "-",
            mtType: inventory?.mtType || "-",
            serialNumber: inventory?.serialNumber || "-",
            stockType: stockType || "-",
            ver: inventory?.ver || "-",

            // Software versions
            currentVersion: softwareVersions?.currentVersion || "-",
            alternateVersion: softwareVersions?.alternateVersion || "-",
            currentLanguageVersion: softwareVersions?.currentLanguageVersion || "-",

            // Attribute
            connected: attributes?.connected ? attributes?.connected.charAt(0).toUpperCase() + attributes?.connected.slice(1) : "False",
            timeConnected: attributes?.timeConnected ? moment.unix(attributes?.timeConnected/1000).local().format('YYYY-MM-DD HH:mm:ss') : "-",
            timeDisconnected: attributes?.timeDisconnected ? moment.unix(attributes?.timeDisconnected/1000).local().format('YYYY-MM-DD HH:mm:ss') : "-",

            // Token
            nextToken: nxtToken,

            //HMIActivation
            hmiActivated:item.hmiActivated
          };

          return newItem;
        });
        return of(mapped);
      }));
  }

  onRowSelected(e: RowSelectedEvent): void {

    const selectedRows = this.agGrid.api.getSelectedRows()

    const selectedDevices = selectedRows.map(item => {
      return item;
    })

    this.selectedDevices.emit(selectedDevices)
  }
}