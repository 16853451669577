import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ToastService } from '@siemens/ix-angular';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-crmt-job-status',
  templateUrl: './crmt-job-status.component.html',
  styleUrls: ['./crmt-job-status.component.css']
})
export class CrmtJobStatusComponent implements OnInit {

  @Input() jobId!: string;
  public status: string = ''
  public isLoading: boolean = false;
  public isDisabled: boolean= false;
  public details: any
  jobStatuses: { key: string, value: string }[] = [];


  private jobSubscription?: Subscription

  constructor(
    private _apiService: ApiService,
  private toastService : ToastService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

    const jobId = changes["jobId"].currentValue

    if(jobId) {

      if(this.jobSubscription) {
        this.jobSubscription.unsubscribe()
      }
      this.getJobStatus(jobId)

    }
  }
  refreshJobStatus(){
    this.isDisabled =true;
    this.getJobStatus(this.jobId);
    this.toastService.setPosition('top-right');
    setTimeout(() => {
      this.isDisabled= false;
    }, 30000);
    this.toastService.show({
      message: 'Refresh Job Status button will be available after 30 seconds.',
      type: 'info'
    });
  }
  getJobStatus(jobId:string){
      // Fetch and display the job status
      this.isLoading = true;
      this.jobSubscription = this._apiService.getJobStatus(jobId).subscribe(data => { 
        this.status = data.message.job.status.replace('_', ' ')
        this.status=this.status.replace('CANCELED', 'CANCELLED');
        this.details = data.message.job.jobProcessDetails;
        const indDeviceStatus = data.message.jobstatus;
        this.jobStatuses = Object.keys(indDeviceStatus).map(key =>{
        indDeviceStatus[key]= indDeviceStatus[key].replace('CANCELED', 'CANCELLED');
            if(indDeviceStatus[key]==="Loading Job Status"){
              indDeviceStatus[key]=this.status;
          }
         return ({ key, value: indDeviceStatus[key] })
        } );
        this.isLoading= false;
      })
  }
  ngOnDestroy() {
    this.jobSubscription?.unsubscribe();
  }    
}
