// The component makes use of AG Grid's tree-control capability and is
// based on the examples on their website.
// See https://www.ag-grid.com/angular-data-grid/tree-data/
import { Component } from '@angular/core';
import {
  ColDef,
  GetDataPath,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  IRowNode,
  RowDragEndEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { DeleteRowComponent } from 'src/app/cell-renderers/delete-row/delete-row.component';
import { DetailsLinkComponent } from 'src/app/cell-renderers/details-link/details-link.component';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { NewOrganisationData } from '../create-group/create-group.component';
import * as moment from 'moment';
import { IconCellRendererComponent } from 'src/app/cell-renderers/icon-cell-renderer/icon-cell-renderer.component';

@Component({
  selector: 'app-fleet-tree',
  templateUrl: './fleet-tree.component.html',
  styleUrls: ['./fleet-tree.component.css']
})
export class FleetTreeComponent {

  

  public disablePrivileged = true

  private gridApi!: GridApi;
  private id = 0

  public columnDefs: ColDef[] = [];
  
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  public commonColDef: ColDef[]=[
    { field: 'description',flex:1, minWidth: 200  },
    { field: 'connected',cellRenderer:IconCellRendererComponent , flex:1 ,minWidth: 130 },
    { field: 'configuration', cellRenderer: DetailsLinkComponent, flex:1 ,minWidth: 145, sortable: false, filter: false },
    { field: 'ver', headerName: 'S/W Version', flex:1 ,minWidth: 130  },
    { field: 'currentVersion', headerName: 'Current S/W Build', flex:1 ,minWidth: 180 },
    { field: 'alternateVersion', headerName: 'Available S/W Build', flex:1 ,minWidth: 220  },
    { field: 'serialNumber', flex:1 ,minWidth: 150 },
    { field: 'mtType', headerName: 'Trx Type', flex:1 ,minWidth: 150},
    { field: 'mtModel', headerName: 'Trx Model', flex:1 ,minWidth: 150 },
    { field: 'mtAppver', headerName: 'Trx S/W Version', flex:1 ,minWidth: 180},
    { field: 'mtIMEI', headerName: 'Trx IMEI', flex:1 ,minWidth: 180},
    { field: 'stockType',headerName: 'Vehicle Type', flex:1 ,minWidth: 150 },
    { field: 'currentLanguageVersion', flex:1 ,minWidth: 225 },
    { field: 'timeConnected', flex:1 ,minWidth: 180 },
    { field: 'timeDisconnected',flex:1, minWidth: 200}
  ];
  public rowData: any[] | null = []
  public groupDefaultExpanded = 0;
  public getDataPath: GetDataPath = (data: any) => {
    return data.filePath;
  };
  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  };
  public autoGroupColumnDef: ColDef = {
    rowDrag: params => params.node.data.type == 'file' && !this.disablePrivileged,
    headerName: 'Device Fleet',
    minWidth:300,
    width:300,
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: FileCellRenderer,
    },
    sortable: true, filter: true,
    cellClass: 'no-right-border',
    pinned:'left',
  };


  constructor(
    private readonly permissions: PermissionsService,
    private _apiService: ApiService) {

    }
    
    ngOnInit() { 
    this._apiService.fleetDeviceTree().subscribe((data: any) => {
      this.rowData = data.message.map((row: any) => {
        
        if(row.type==='file'){
          //To show details of a thing
          return this.getDetailsForRow(row);
        }
        row.id = this.newId();
        return row;
      })
    })

    this.permissions.getUserLevel().subscribe(level => {

      this.disablePrivileged = level < UserLevel.PRIVILEGED

      if(level >= UserLevel.PRIVILEGED) {

        // All the user to delete rows
        this.columnDefs = [
          ...this.commonColDef,
          { cellRenderer: DeleteRowComponent, pinned:'right', minWidth:50, width:60 },
        ];
      }
      else {
        // Don't allow the user to delete rows
        this.columnDefs = this.commonColDef
      }
    })
  }
 private getDetailsForRow(row: any,){
          const inventory = row.shadow?.inventory;
          const softwareVersions = row.shadow?.softwareVersions;
          const attributes = row.attributes;
          let stockType: string;
          switch (inventory?.stockType) {
            case 0: stockType = "Engine"; break;
            case 1: stockType = "Coach"; break;
            default: stockType = "-"; break;
          }
           
          return {
            id: this.newId(),
            deviceID:row.deviceID,
            deviceName:row.deviceName,
            filePath: row.filePath,
            type:row.type,
            mtAppver: inventory?.mtAppver || row.mtAppver || "-" ,
            mtIMEI: inventory?.mtIMEI || row.mtIMEI ||"-",
            mtModel: inventory?.mtModel || row.mtModel || "-",
            mtType: inventory?.mtType || row.mtType || "-",
            serialNumber: inventory?.serialNumber || row.serialNumber || "-",
            stockType: row.stockType || stockType ||  "-",
            ver: inventory?.ver || row.ver || "-",

            // Software versions
            currentVersion: softwareVersions?.currentVersion || row.currentVersion || "-",
            alternateVersion: softwareVersions?.alternateVersion || row.alternateVersion || "-",
            currentLanguageVersion: softwareVersions?.currentLanguageVersion || row.currentLanguageVersion || "-",

            // Attribute
            connected: attributes?.connected ? attributes?.connected.charAt(0).toUpperCase() + attributes?.connected.slice(1) : "False",
            timeConnected: attributes?.timeConnected ? moment.unix(attributes?.timeConnected/1000).local().format('YYYY-MM-DD HH:mm:ss') : (row.timeConnected || "-") ,
            timeDisconnected: attributes?.timeDisconnected ? moment.unix(attributes?.timeDisconnected/1000).local().format('YYYY-MM-DD HH:mm:ss') : (row.timeDisconnected || "-"),
            hmiActivated: row.hmiActivated
          }
 }
  onRowDragEnd(event: RowDragEndEvent) {

    // this is the row the mouse is hovering over
    var overNode = event.overNode;
    if (!overNode) {
      return;
    }
    // folder to drop into is where we are going to move the file/folder to
    var folderToDropInto =
      overNode.data.type === 'folder'
        ? // if over a folder, we take the immediate row
          overNode
        : // if over a file, we take the parent row (which will be a folder)
          overNode.parent;
    // the data we want to move
    var movingData = event.node.data;
    // take new parent path from parent, if data is missing, means it's the root node,
    // which has no data.
    var newParentPath = folderToDropInto!.data
      ? folderToDropInto!.data.filePath
      : [];
    var needToChangeParent = !arePathsEqual(newParentPath, movingData.filePath);
    // check we are not moving a folder into a child folder
    var invalidMode = isSelectionParentOfTarget(event.node, folderToDropInto);
    if (invalidMode) {
      console.log('invalid move');
    }
    if (needToChangeParent && !invalidMode) {

      const oldOrg = event.node.data.filePath[0]
      const newOrg = newParentPath[0]
    
      if(oldOrg == newOrg) {

        var updatedRows: any[] = [];
        this.moveToPath(newParentPath, event.node, updatedRows);
        this.gridApi.applyTransaction({
          update: updatedRows,
        });
        this.gridApi.clearFocusedCell();
      }
      else {
        var updatedRows: any[] = [];
        this.copyToPath(newParentPath, event.node, updatedRows);
        this.gridApi.applyTransaction({
          add: updatedRows,
        });
        this.gridApi.clearFocusedCell();        
      }
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    if (this.rowData != null) {
      this.gridApi.showLoadingOverlay();
    } else {
      this.gridApi.hideOverlay();
    }
  }

  public addOrganization(newOrganisationData: NewOrganisationData) {

    const newEntry = {
      
      id: this.newId(),
      filePath: [newOrganisationData.newOrganization],
      description: newOrganisationData.newOrganizationDescription,
      type: 'folder'
    }      

    this.gridApi.applyTransaction({
      add: [newEntry],
    });
  }

  public addSubgroup(event: any) {

    const parentRow = this.gridApi.forEachNode((node: any) => {

      const data = node.data
      const folderName = data.filePath[data.filePath.length - 1]

      if(data.type == 'folder' && folderName == event.parentGroup) {

        const filePathCopy = data.filePath.slice();
        filePathCopy.push(event.newGroupName);
    
        const newEntry = {
          
            id: this.newId(),
            filePath: filePathCopy,
            type: 'folder'
        }      
    
        this.gridApi.applyTransaction({
          add: [newEntry],
        });
      }
    })

  }

  private copyToPath(
    newParentPath: string[],
    node: IRowNode,
    allUpdatedNodes: any[]
  ) {
    // last part of the file path is the file name
    var oldPath = node.data.filePath;
    const rowData = node.data;
    const oldOrg = oldPath[0]
    const newOrg = newParentPath[0]
    const thingName= node.data.deviceName;
    const thingDisplayName = oldPath[oldPath.length - 1] 
    const newParent = newParentPath[newParentPath.length - 1];
    
    const newParentPathCopy = newParentPath.slice();
    newParentPathCopy.push(thingDisplayName);

    const newEntry = this.getDetailsForRow({...rowData, filePath: newParentPathCopy})  
    
    allUpdatedNodes.push(newEntry);

    this._apiService.fleetAddThingToGroup(newParent, thingName).subscribe((result: any) => {
      console.log("Result", result)
    })  
  }
  
  // this updates the filePath locations in our data, we update the data
  // before we send it to AG Grid
  
  private moveToPath(
    newParentPath: string[],
    node: IRowNode,
    allUpdatedNodes: any[]) {
      
    // last part of the file path is the file name
    var oldPath = node.data.filePath;
    const oldParent = oldPath[oldPath.length - 2];
    const thingName= node.data.deviceName;
    const thingDisplayName = oldPath[oldPath.length - 1]
    const newParent = newParentPath[newParentPath.length - 1];
    
    const newParentPathCopy = newParentPath.slice();
    newParentPathCopy.push(thingDisplayName);
    node.data.filePath = newParentPathCopy;
    allUpdatedNodes.push(node.data);
  
    this._apiService.fleetMoveThingToGroup(oldParent, newParent, thingName).subscribe((result: any) => {
      console.log("Result", result)
    })
  }

  private newId() {
    return this.id++
  }
}

var valueFormatter = function (params: ValueFormatterParams) {
  return params.value ? `V${params.value}` : '';
};
function isSelectionParentOfTarget(
  selectedNode: IRowNode,
  targetNode: IRowNode | null
) {
  let children = [...(selectedNode.childrenAfterGroup || [])];
  if (!targetNode) {
    return false;
  }
  while (children.length) {
    const node = children.shift();
    if (!node) {
      continue;
    }
    if (node.key === targetNode.key) {
      return true;
    }
    if (node.childrenAfterGroup && node.childrenAfterGroup.length) {
      children.push(...node.childrenAfterGroup);
    }
  }
  return false;
}
function arePathsEqual(path1: string[], path2: string[]) {
  if (path1.length !== path2.length) {
    return false;
  }
  var equal = true;
  path1.forEach(function (item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
}

export class FileCellRenderer {
  private eGui: any;

  init(params: any) {
    var tempDiv = document.createElement('div');
    var groupName = params.value.indexOf(':')
    var value = groupName > 0 ? params.value.substring(groupName + 1) : params.value;
    var icon = getFileIcon(params.value);
    tempDiv.innerHTML = icon
      ? '<i class="' +
        icon +
        '"/>' +
        '<span class="filename">' +
        value +
        '</span>'
      : value;

    this.eGui = tempDiv.firstChild;
  }
  getGui() {
    return this.eGui;
  }
}

function getFileIcon(filename: string) {
  const icon = filename.endsWith('1') || filename.endsWith('.wav')
    ? 'far fa-file-audio'
    : filename.endsWith('.xls')
    ? 'far fa-file-excel'
    : filename.endsWith('.txt')
    ? 'far fa-file'
    : filename.endsWith('.pdf')
    ? 'far fa-file-pdf'
    : 'far fa-folder';

    return icon
}
