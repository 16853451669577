import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService, UploadFileState } from '@siemens/ix-angular';
import { Subscription } from 'rxjs';
import { CrmtCommandSelectorComponent } from 'src/app/components/crmt-command-selector/crmt-command-selector.component';
import { CrmtDateSelectorComponent } from 'src/app/components/crmt-date-selector/crmt-date-selector.component';
import { CrmtDeviceSelectorComponent } from 'src/app/components/crmt-device-selector/crmt-device-selector.component';
import { ReviewKeyPair } from 'src/app/components/crmt-job-review/crmt-job-review.component';
import { ApiService, CommandJobRequest } from 'src/app/services/api.service';
import { NextBtnStateService } from 'src/app/services/next-btn-state.service';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-send-command-page',
  templateUrl: './send-command-page.component.html',
  styleUrls: ['./send-command-page.component.css']
})
export class SendCommandPageComponent implements OnInit {

  @ViewChild(CrmtCommandSelectorComponent) commandSelector?: CrmtCommandSelectorComponent
  public commandSelectorData?: any
  @ViewChild(CrmtDeviceSelectorComponent) deviceSelector?: CrmtDeviceSelectorComponent
  public deviceSelectorData?: any
  @ViewChild(CrmtDateSelectorComponent) dateSelector?: CrmtDateSelectorComponent
  public dateSelectorData?: any
  
  public reviewData!: ReviewKeyPair[]
  public updateCommand = '';

  @ViewChild('customToast', { read: TemplateRef })
  customModalRef!: TemplateRef<any>;

  public state = UploadFileState.SELECT_FILE;
  public files: File[] = []
  public jobId: string = ""
  public selectedTab = 0
  public isError = false

  public status: string[] = [
    'open',
    'open',
    'open',
    'open'
  ]

  public selectedFile?: string
  public submitted = false
  public disableStandard = true
  public disableNextBtn! : boolean
  private subscription!: Subscription

  constructor(
    private readonly permissions: PermissionsService,
    private _apiService: ApiService,
    private readonly toastService: ToastService, 
    private nextBtnStateService: NextBtnStateService, 
    private router: Router) {}
    
  ngOnInit(): void {

    this.permissions.getUserLevel().subscribe(level => {
      this.disableStandard = level < UserLevel.STANDARD
    })

    this.subscription = this.nextBtnStateService.getDisableNextBtn()
    .subscribe(value => {
      this.disableNextBtn = value
    });
  }
  
  public getCommandType(e: any) {
    if(e) this.updateCommand = e;
  }
  
  public deviceHandler(devices: string[]) {

    if(devices.length > 0) {
      this.status[1] = 'done'
    }
    else {
      this.status[1] = 'open'
    }
  }

  public previous() {
    
    if(this.selectedTab > 0) {
      this.selectedTab--
    }
  }

  public navigation(selected : number) {
    if(this.selectedTab > selected) {
      this.selectedTab = selected
    } else if(this.selectedTab == 0 || this.selectedTab == 1 || this.selectedTab == 2) {
      this.next()
    } 
  }

  // TODO: This will be refactored in due course
  public next() {
    if(this.selectedTab == 0) {
      this.commandSelectorData = this.commandSelector?.getData()

      if(this.commandSelectorData?.done) {
        this.status[0] = 'done'
        this.incrementTab()
      }
      else {
        this.status[0] = 'error'
      }
    }
    else if(this.selectedTab == 1) {
      this.deviceSelectorData = this.deviceSelector?.getData()

      if(this.deviceSelectorData?.done) {
        this.status[1] = 'done'
        this.incrementTab()
      }
      else {
        this.status[1] = 'error'
      }
    }
    else if(this.selectedTab == 2) {
      this.dateSelectorData = this.dateSelector?.getData()

      if(this.dateSelectorData?.done) {
        this.status[2] = 'done'

        this.reviewData = [
          { key: "Type", value: this.commandSelectorData?.type},
          this.deviceSelectorData.groupMode ? {
            key: "Groups", value: this.updateGroupNames(this.deviceSelectorData.groups).join(', ')
          } : { 
            key: "Devices", value: this.getDeviceStockNumber(this.deviceSelectorData?.devices).join(', ')
          },
          { key: "Schedule", value: this.dateSelectorData?.now ? 'Deploy now' : `Deploy later (${this.dateSelectorData.startTime})`},
          ];

        this.incrementTab()
      }
    }
    
    else if(this.selectedTab == 3) {
      this.submit()
      this.incrementTab()
    }
  }

  private getDeviceStockNumber(selection: string[]) {
    let deviceStockNumber: string[] = []
    selection.forEach((element: any) => {
      deviceStockNumber.push(element.engineNumber)
    });
    return deviceStockNumber
  }

  private updateGroupNames(strings: string[]): string[] {
    return strings.map(groupName => {
     var indexOfSpecialCharacter = groupName.indexOf(':')
     if (indexOfSpecialCharacter > 0) {
        return groupName.substring(indexOfSpecialCharacter + 1);
      } else {
        return groupName;
      }
   });
  }

  public sendAnotherCommand() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['send-command'])
    });
  }

  private incrementTab(): void {
    if(this.selectedTab < 4) {
      this.selectedTab++
    }
  }

  private submit() {
    this.status[3] = 'done'
    const file = this.commandSelectorData.file

    // Convert the GUI file type to an API file type
    const command = this.commandSelectorData.type;
    const groupMode = this.deviceSelectorData.groupMode
    const job: CommandJobRequest = {
      command,
      groups: groupMode ? this.deviceSelectorData?.groups : null,
      devices: groupMode ? null : this.getDeviceName(this.deviceSelectorData?.devices),
      scheduleLater: this.dateSelectorData?.now == false,
      startTime: this.dateSelectorData?.startTime,
      swVersion: this.commandSelectorData.swVersion,
      stockType: this.commandSelectorData.stockType,
      stockNumber: this.commandSelectorData.stockNumber,
      languageCode: this.commandSelectorData.languageCode
    }

    return this._apiService.sendCommand(job).subscribe({
      next: (data) => {
        this.jobId = data.message.jobId
        this.showToastMessage()
        this.submitted = true
      },
      error: (e) => {
        if(e.status == 0){// This is the 0 = unknown Error
          this.errorToast("Unable to process your request. Please try again later or contact the administrator.")
        }else {
          this.errorToast(`Error occurred while sending command. Please contact administrator.`)
        }
        this.submitted = false
        this.isError = true
        this.previous()
      }
    })
  }

  private getDeviceName(selection: string[]) {
    let deviceName: string[] = []
    selection.forEach((element: any) => {
      deviceName.push(element.name)
    });
    return deviceName
  }

  async showToastMessage() {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: 'Deployment job submitted',
      type: "success"
    });
  }


  async errorToast(message: string) {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: message,
      type: 'error'
    });
  }

  ngOnDestroy(): void {
    this.nextBtnStateService.setDisableNextBtn(true)
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
