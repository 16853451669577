import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ToastService } from '@siemens/ix-angular';
import { CrmtDeviceSelectorComponent } from 'src/app/components/crmt-device-selector/crmt-device-selector.component';
import { CrmtFileEditorComponent } from 'src/app/components/crmt-file-editor/crmt-file-editor.component';
import { ReviewKeyPair } from 'src/app/components/crmt-job-review/crmt-job-review.component';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { CrmtFile } from 'src/app/utils/crmt-file';

@Component({
  selector: 'app-retrieve-edit-deploy',
  templateUrl: './retrieve-edit-deploy.component.html',
  styleUrls: ['./retrieve-edit-deploy.component.css']
})
export class RetrieveEditDeployComponent implements OnInit {

  @ViewChild(CrmtDeviceSelectorComponent) deviceSelector?: CrmtDeviceSelectorComponent
  public deviceSelectorData?: any

  @ViewChild(CrmtFileEditorComponent) fileEditor?: CrmtFileEditorComponent
  public fileEditorData?: any
  
  @ViewChild('customToast', { read: TemplateRef })
  customModalRef!: TemplateRef<any>;

  public selectedTab = 0

  public device!: string;
  public crmtFile?: CrmtFile

  public status: string[] = [
    'open',
    'open',
    'open'
  ]

  public submitted = false
  public reviewData: ReviewKeyPair[] = []
  public jobId: string = ""
  public disableStandard = true

  constructor(
    private readonly permissions: PermissionsService,
    private _apiService: ApiService,
    private readonly toastService: ToastService) {}
  
  ngOnInit(): void {

    this.permissions.getUserLevel().subscribe(level => {

      this.disableStandard = level < UserLevel.STANDARD
    })
  }
  
  public deviceHandler(devices: string[]) {

    if(devices.length > 0) {
      this.status[1] = 'done'
    }
    else {
      this.status[1] = 'open'
    }
  }

  public previous() {
    
    if(this.selectedTab > 0) {
      this.selectedTab--
    }
  }

  public navigation(selected : number) {
    if(this.selectedTab > selected) {
      this.selectedTab = selected
    } else if(this.selectedTab == 0 || this.selectedTab == 1) {
      this.next()
    }
  }

  // TODO: This will be refactored in due course
  public next() {

    if(this.selectedTab == 0) {

      this.deviceSelectorData = this.deviceSelector?.getData()

      if(this.deviceSelectorData?.done) {
        this.status[0] = 'done'
        this.device = this.deviceSelectorData.devices[0]
        this.incrementTab()
      }
      else {
        this.status[0] = 'error'
      }
    }
    else if(this.selectedTab == 1) {

      this.fileEditorData = this.fileEditor?.getData()
      if(this.fileEditorData?.done) {

        this.status[1] = 'done'

        this.reviewData = [
          { key: "File type", value: this.fileEditorData.fileType},
          { key: "Devices", value: this.device},
          { key: "Modified", value: 'True'},
        ]

        this.incrementTab()
      }
      else {
        this.errorToast("The file has not been edited")
        this.status[1] = 'error'
      }
    }
    else if(this.selectedTab == 2) {
      
      this.submit()
      this.incrementTab()
    }

  }

  private incrementTab(): void {

    if(this.selectedTab < 3) {
      this.selectedTab++
    }
  }

  private submit() {

    this.submitted = true
    this.status[2] = 'done'

    this._apiService.putFile(
      `test/encoded.spb`,
      this.fileEditorData.fileType,
      [this.device],
      this.fileEditorData.fileData)
    .subscribe({
      next: (data) => {

        this.jobId = data.message.jobId
        this.showToastMessage()
      },
      error: (e: any) => {
        this.errorToast(e.message)
        this.submitted = false            
      }
    })
  }

  async showToastMessage() {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: 'Deployment job submitted',
      type: "success"
    });
  }


  async errorToast(message: string) {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: message,
      type: 'error'
    });
  }
}



